import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing head metadata
import Hero from "../../Components/Hero";
import FeatureSection from "../../Components/FeatureSection";
import SplitSection from "../../Components/SplitSection";
import InformationSection from "src/Components/InformationSection";
import InformationalCardsSection from "src/Components/InformationalCardsSection";
import { ArrowUpward, Rocket, Star } from "@mui/icons-material";

type Props = {};

const IndexPage = (props: Props) => {
  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>
          Short-Term Rental Monitoring | STR Enforcement & Compliance
        </title>

        <meta
          name="description"
          content="Learn how STR Monitoring tools adapt to your local ordinances, ensuring compliance, enforcement, and high tax revenue for your community or HOA."
        />
        <meta
          name="keywords"
          content="short-term rental, STR monitoring, community compliance, HOA, rental activity, local government tools, local ordinances, tax revenue"
        />
        <meta property="og:title" content="Short-Term Rental Monitoring" />
        <meta
          property="og:description"
          content="Empower your community with STR Monitoring tools to maintain compliance, protect neighborhoods, and streamline short-term rental management."
        />
        <meta property="og:image" content="/images/hero.jpg" />
        <meta property="og:url" content="https://strmonitoring.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Short-Term Rental Monitoring" />
        <meta
          name="twitter:description"
          content="Empower your community with STR Monitoring tools to maintain compliance and streamline rental management."
        />
        <meta name="twitter:image" content="/images/hero.jpg" />
        <link rel="canonical" href="https://strmonitoring.com/" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            short_name: "STR Monitor",
            name: "Short-Term Rental Monitoring",
            description:
              "Learn how STR Monitoring helps communities, HOAs, and local governments manage short-term rental activity effectively and protect their neighborhoods.",
            url: "https://strmonitoring.com/",
            image: "/images/hero.jpg",
            author: {
              "@type": "Organization",
              name: "STR Monitoring",
            },
            mainEntity: [
              {
                "@type": "Service",
                name: "Custom Email Alerts",
                description:
                  "Stay informed with personalized email notifications whenever new activity is detected in your neighborhood.",
              },
              {
                "@type": "Service",
                name: "Comprehensive Reports",
                description:
                  "Generate detailed reports to streamline your process for identifying short-term rental violations.",
              },
              {
                "@type": "Service",
                name: "Accurate Location Insights",
                description:
                  "Gain unmatched precision with STR Monitoring, pinpointing exact locations and accessing photos of short-term rental listings.",
              },
              {
                "@type": "Service",
                name: "Modern Monitoring Dashboard",
                description:
                  "Access a sleek, user-friendly interface to view all relevant data for your monitored properties, including current and historical bookings, flagged violations with direct listing links, and potential revenue insights for each violator.",
              },
            ],
          })}
        </script>
      </Helmet>

      {/* Main Content */}
      <div>
        <Hero
          title="STR Monitoring"
          subTitle="We're still under development! Be the first to know when we launch by signing up for notifications, or try out our free airbnb search in the meantime to see if any short term rentals are around a specific property"
          buttonText="Sign Up for Notifications"
          buttonLink="/sign-up"
          backgroundImage="/images/hero.jpg"
        />

        <InformationSection
          // Main Section
          title="Short-Term Rental Monitoring Made Easy"
          introText="Short-Term Rental (STR) Monitoring solution, you can identify up to 95% of STR properties in your jurisdiction and achieve an average compliance rate of over 90%."
          identificationAndComplianceText="Communities using STR Monitoring can expect to generate more tax revenue and licensing fees in their first year, all while maintaining an extremly high compliance rate."
          firstYearExampleText="But it’s not just about the numbers—it’s about adaptability. Our solution is fully configurable to mirror your local ordinances, ensuring accurate compliance for both properties and operators."
          adaptabilityText="You can rest assured knowing all requirements are being met."
          enforcementText="In addition to simplifying regulatory compliance, STR Monitoring platform streamlines the enforcement of local ordinances, making it easier for everyone involved."
          // Problem Section
          problemTitle="The Problem"
          problemDescription="The short-term rental industry is thriving across the country, bringing both opportunities and challenges for local governments. While increased tax revenue from STRs can be substantial, municipalities need effective solutions to manage legal complexities, ensure compliance, enforce regulations, and streamline revenue collection."
          // Solution Section
          solutionTitle="The Solution"
          solutionDescription="STR Monitoring provides local governments with augmented revenue-management capabilities. Our holistic approach helps communities fully understand and nurture the short-term rental market. We handle the heavy lifting, tackle online travel companies, and provide Concierge Customer Service that will delight your constituents."
          imageSrc="/images/easy_work_life.jpg"
          imageAlt="Short-Term Rental Illustration"
        />

        <FeatureSection
          sectionText="Our Main Features"
          headerText="How We Make a Difference in Your Community"
          subText="Every community has unique needs when it comes to monitoring and managing short-term rentals. Our solutions empower neighborhoods, HOAs, and local governments with tools to maintain compliance and protect their communities."
          cards={[
            {
              image: "/images/email-alert.jpg",
              title: "Custom Email Alerts",
              description:
                "Stay informed with personalized email notifications whenever new activity is detected in your neighborhood. Stay ahead and take action effortlessly.",
            },
            {
              image: "/images/report-creation.jpg",
              title: "Comprehensive Reports",
              description:
                "Easily generate and download detailed reports to streamline your process when identifying short-term rental violations. Empower your decision-making with accurate data.",
            },
            {
              image: "/images/location-finder.jpg",
              title: "Accurate Location Insights",
              description:
                "Gain unmatched precision with STR Monitoring. Pinpoint exact locations and access photos of potential short-term rental listings, giving you the tools to verify with confidence.",
            },
            {
              image: "/images/dashboard.jpg",
              title: "Modern Monitoring Dashboard",
              description:
                "Access a sleek, user-friendly interface to view all relevant data for your monitored properties, including current and historical bookings, flagged violations with direct listing links, and potential revenue insights for each violator.",
            },
          ]}
        />
        <InformationalCardsSection
          heading="Empowering Compliance and Revenue Recovery"
          subheading="We empower jurisdictions, HOAs, and local governments by providing a comprehensive toolkit to enhance visibility and control over the short-term rental landscape, addressing critical areas like:"
          bulletItems={[
            "Recovering Lost Tax Revenue",
            "Enforcing Ordinance Compliance",
            "Streamlining STR Inspection and Licensing Processes",
            "Addressing Health and Safety Concerns",
          ]}
          imageSrc="/images/man_working_from_home.jpg"
          imageAlt="Short-Term Rental Monitoring Illustration"
          imagePosition="left"
          cards={[
            {
              icon: <Star fontSize="large" />,
              title: "Precision Property Detection",
              description:
                "Pinpoint over 98% of short-term rentals with our advanced detection technology, combining AI precision and human oversight for unmatched accuracy. Our platform identifies exact addresses to simplify compliance enforcement for local governments, ensuring no detail is overlooked.",
            },
            {
              icon: <Rocket fontSize="large" />,
              title: "Maximize Compliance Efficiency",
              description:
                "Elevate compliance rates to over 90% with our robust monitoring tools. Utilize real-time data, automated alerts, and straightforward enforcement guidelines tailored to the specific needs of any locality, from rural communities to sprawling urban centers.",
            },
            {
              icon: <ArrowUpward fontSize="large" />,
              title: "Enhance Revenue Streams",
              description:
                "Unlock potential revenue with our efficient, paperless enforcement system. Secure every Transient Occupancy Tax (TOT) and licensing fee, closing gaps in collections and boosting your municipality's financial health.",
            },
          ]}
        />

        <SplitSection
          image="/images/building-neighbors.png"
          header="Helping Communities Nationwide"
          description={[
            "We partner with neighborhoods, HOAs, and local governments across the country to monitor and manage short-term rental activity effectively.",
            "Our solutions are tailored to meet your community's unique needs.",
            "Ready to take the next step?",
            "• Learn More: Connect with our team for a personalized consultation.",
            "• Explore Our Demo: Sign up today or try out our free demo with mock data to see how it works for your community.",
          ]}
          buttonText="Free Demo Try Now"
          buttonLink="/demo"
        />
      </div>
    </>
  );
};

export default IndexPage;
