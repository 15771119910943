import React, { useState } from "react";
import Map, { MapRef, Marker, Popup, Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { StayData } from "src/Types/airbnb.types";
import { SubmittedProperty } from "src/Types/submittedProperties.types";
import mapboxgl from "mapbox-gl";
// import MapboxDraw from "@mapbox/mapbox-gl-draw"; // Import Mapbox Draw
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"; // Import Mapbox Geocoder
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

interface Props {
  createdList: any;
  mapRef: React.RefObject<MapRef>; // Accept mapRef as a prop
  setPopover: any;
  popover: any;
  setSearchResults: any;
  submittedProperties: SubmittedProperty[];
  foundSTRProperties: {
    airbnbResults: { propertyId: string; filteredResults: StayData[] }[];
  };
}

// type DrawEvent = {
//   features: Array<{
//     type: string;
//     geometry: {
//       type: string;
//       coordinates: any;
//     };
//     properties: Record<string, any>;
//     id?: string;
//   }>;
// };

// Function to create a GeoJSON circle
const createGeofence = (center: [number, number], radiusInMiles: number) => {
  const radiusInMeters = radiusInMiles * 1609.34; // Convert miles to meters
  const points = 64; // Number of points to form the circle
  const coordinates: [number, number][] = [];

  // Use the center's latitude in radians for the cosine adjustment.
  const latCos = Math.cos(center[1] * (Math.PI / 180));

  for (let i = 0; i < points; i++) {
    const angle = (i / points) * (2 * Math.PI); // Divide the circle into equal angles

    // Adjust dx using the cosine of the latitude
    const dx = (radiusInMeters * Math.cos(angle)) / (111320 * latCos);
    const dy = (radiusInMeters * Math.sin(angle)) / 110540;

    // Assuming GeoJSON order: [longitude, latitude]
    coordinates.push([center[0] + dx, center[1] + dy]);
  }

  coordinates.push(coordinates[0]); // Close the circle

  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [coordinates],
    },
    properties: {},
  };
};

const MapWithPins: React.FC<Props> = ({
  foundSTRProperties,
  submittedProperties,
  setPopover,
  mapRef,
  setSearchResults,
}) => {
  const [selectedAirbnb, setSelectedAirbnb] = useState<StayData | null>(null);
  const [selectedProperty, setSelectedProperty] =
    useState<SubmittedProperty | null>(null);

  const isMobile = window.innerWidth < 768; // Check for mobile screens
  const initialZoom = isMobile ? 10 : 12; // Adjust zoom for mobile screens

  const handleAirbnbMarkerClick = (location: StayData) => {
    setSelectedAirbnb(location);
    setSelectedProperty(null); // Close SubmittedProperty popup
  };

  const handlePropertyMarkerClick = (property: SubmittedProperty) => {
    setSelectedProperty(property);
    setSelectedAirbnb(null); // Close Airbnb popup
  };

  const handleMapLoad = (map: mapboxgl.Map) => {
    console.log("Map loaded successfully:", map);

    // Initialize Mapbox Draw for geofence creation
    // const draw = new MapboxDraw({
    //   displayControlsDefault: false,
    //   controls: {
    //     polygon: true, // Enable polygon drawing
    //     trash: true, // Enable delete button
    //   },
    // });
    // map.addControl(draw);

    // // Handle Geofence Create and Update Events
    // map.on("draw.create", (e: DrawEvent) => {
    //   console.log("Geofence created:", e.features[0]);
    // });

    // map.on("draw.update", (e: DrawEvent) => {
    //   console.log("Geofence updated:", e.features[0]);
    // });

    // map.on("draw.delete", (e: DrawEvent) => {
    //   console.log("Geofence deleted:", e.features);
    // });

    // Initialize Geocoder Control
    const geocoder = new MapboxGeocoder({
      accessToken:
        "pk.eyJ1Ijoic29sYXJzdGFyMTAxIiwiYSI6ImNsYXR1bHBucjAwNTYzcG82enUyZzh6a2kifQ.fFHzzpgdk67aj56tLYRQjQ",
      marker: true, // Disable automatic marker placement
    });

    // Handle Search Results
    geocoder.on("result", (e) => {
      setSearchResults(e.result);
      const { center, place_name } = e.result;

      const map = mapRef.current?.getMap();
      if (map && center) {
        const markerElement = document.createElement("div");
        markerElement.style.width = "20px";
        markerElement.style.height = "20px";
        markerElement.style.backgroundColor = "red";
        markerElement.style.borderRadius = "50%";

        // Add marker to the map
        new mapboxgl.Marker({ element: markerElement })
          .setLngLat(center)
          .addTo(map);

        // Fly to the searched location
        map.flyTo({
          center: center,
          zoom: 14,
        });
      }

      // Show popover at the searched location
      setPopover({
        isVisible: true,
        coordinates: center,
        address: place_name,
      });
    });

    // Add Geolocate Control
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
    });
    map.addControl(geolocate, "bottom-right");

    geolocate.on("geolocate", (e) => {
      console.log("Geolocation triggered:", e.coords);
    });
  };

  return (
    <>
      <Map
        ref={mapRef}
        initialViewState={{
          longitude: -95.54,
          latitude: 29.65,
          zoom: initialZoom,
        }}
        onLoad={(e) => handleMapLoad(e.target)}
        mapboxAccessToken={
          "pk.eyJ1Ijoic29sYXJzdGFyMTAxIiwiYSI6ImNsYXR1bHBucjAwNTYzcG82enUyZzh6a2kifQ.fFHzzpgdk67aj56tLYRQjQ"
        }
        style={{ width: "100%", height: "88vh" }}
        mapStyle="mapbox://styles/solarstar101/clabl7lkj005u15ocmh23ghmq"
        dragPan={true}
        dragRotate={false}
        touchZoomRotate={true}
        scrollZoom={isMobile ? false : { smooth: true }}
      >
        {/* Render Airbnb Markers */}
        {foundSTRProperties &&
          foundSTRProperties.airbnbResults.map((airbnb) =>
            airbnb.filteredResults.map((location) => (
              <Marker
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                  handleAirbnbMarkerClick(location);
                }}
                key={`airbnb-${location.id}`}
                longitude={location.longitude}
                latitude={location.latitude}
                anchor="bottom"
              >
                <div
                  style={{
                    position: "absolute",
                    width: isMobile ? "20px" : "30px",
                    height: isMobile ? "20px" : "30px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: isMobile ? "10px" : "12px",
                    }}
                  >
                    Airbnb
                  </span>
                </div>
              </Marker>
            ))
          )}

        {/* Render SubmittedProperty Markers and Geofences */}
        {submittedProperties &&
          submittedProperties.map((property) => {
            const geofence = createGeofence(
              [property.lngCoordinates, property.latCoordinates],
              1
            ); // 1 mile

            return (
              <React.Fragment key={`property-${property.propertyId}`}>
                {/* Render Marker */}
                <Marker
                  onClick={(e) => {
                    e.originalEvent.stopPropagation();
                    handlePropertyMarkerClick(property);
                  }}
                  longitude={property.lngCoordinates}
                  latitude={property.latCoordinates}
                  anchor="bottom"
                >
                  <div
                    style={{
                      position: "absolute",
                      width: isMobile ? "15px" : "25px",
                      height: isMobile ? "15px" : "25px",
                      backgroundColor: "blue",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transform: "translate(-50%, -50%)",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: isMobile ? "8px" : "10px",
                      }}
                    >
                      Prop
                    </span>
                  </div>
                </Marker>

                {/* Render Geofence */}
                <Source
                  id={`geofence-${property.propertyId}`}
                  type="geojson"
                  data={geofence}
                >
                  <Layer
                    id={`geofence-layer-${property.propertyId}`}
                    type="fill"
                    paint={{
                      "fill-color": "rgba(0, 0, 255, 0.2)", // Blue with transparency
                      "fill-opacity": 0.4,
                    }}
                  />
                  <Layer
                    id={`geofence-border-${property.propertyId}`}
                    type="line"
                    paint={{
                      "line-color": "blue",
                      "line-width": 2,
                    }}
                  />
                </Source>
              </React.Fragment>
            );
          })}

        {/* Render Airbnb Popup */}
        {selectedAirbnb && (
          <Popup
            longitude={selectedAirbnb.longitude}
            latitude={selectedAirbnb.latitude}
            anchor="top"
            onClose={() => setSelectedAirbnb(null)}
            closeOnClick={true}
          >
            <div style={{ maxWidth: isMobile ? "90vw" : "300px" }}>
              <img
                src={selectedAirbnb.pictures[0] || ""}
                alt={selectedAirbnb.title}
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
              <h3>{selectedAirbnb.name}</h3>
              <p>{selectedAirbnb.roomTypeCategory}</p>
              <p>
                <strong>{selectedAirbnb.pricing.price}</strong>{" "}
                {selectedAirbnb.pricing.qualifier}
              </p>
              <p>
                Rating: {selectedAirbnb.rating.average} (
                {selectedAirbnb.rating.label})
              </p>
              <p>
                <a
                  href={`https://www.airbnb.com/rooms/${selectedAirbnb.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Details
                </a>
              </p>
            </div>
          </Popup>
        )}

        {/* Render SubmittedProperty Popup */}
        {selectedProperty && (
          <Popup
            longitude={selectedProperty.lngCoordinates}
            latitude={selectedProperty.latCoordinates}
            anchor="top"
            onClose={() => setSelectedProperty(null)}
            closeOnClick={true}
          >
            <div style={{ maxWidth: isMobile ? "90vw" : "300px" }}>
              <h3>{selectedProperty.propertyName}</h3>
              <p>{selectedProperty.streetAddress}</p>
              <p>
                {selectedProperty.city}, {selectedProperty.stateOrProvince}{" "}
                {selectedProperty.postalCode}
              </p>
              <p>Owner/Manager: {selectedProperty.ownerOrManagerName}</p>
              <p>Email: {selectedProperty.contactEmail}</p>
              <p>Phone: {selectedProperty.contactPhone}</p>
              <p>Property Type: {selectedProperty.propertyType}</p>
              <p>Units: {selectedProperty.numberOfUnits}</p>
              {selectedProperty.notes && <p>Notes: {selectedProperty.notes}</p>}
            </div>
          </Popup>
        )}
      </Map>
    </>
  );
};

export default MapWithPins;
