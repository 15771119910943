import React from "react";
import { Box, Typography, Container } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#f5faff",
        padding: "1rem 0",
        borderTop: "1px solid #e0e0e0",
      }}
    >
      <Container
        sx={{
          display: "flex",
          maxWidth: "100%!important",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
          },
        }}
      >
        {/* Logo */}
        {/* <Box
          component="img"
          src="https://via.placeholder.com/100x40" // Replace with your logo URL
          alt="Logo"
          sx={{
            height: "40px",
          }}
        /> */}

        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "#555",
            flex: 1,
          }}
        >
          Questions? Contact Us At{" "}
          <a href="mailto:customerservice@strmonitoring.com">
            {" "}
            customerservice@strmonitoring.com{" "}
          </a>
        </Typography>

        {/* Copyright Text */}
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "#555",
            flex: 1,
          }}
        >
          © 2024 STR-Monitoring
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
