import React, { useState, useEffect } from "react";
import { TextField, List, ListItem, ListItemText, Paper } from "@mui/material";
import axios from "axios";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1Ijoic29sYXJzdGFyMTAxIiwiYSI6ImNsYXR1bHBucjAwNTYzcG82enUyZzh6a2kifQ.fFHzzpgdk67aj56tLYRQjQ";

// Define types for the Mapbox API response
interface MapboxFeature {
  id: string;
  place_name: string;
  center: [number, number]; // [longitude, latitude]
}

interface LocationSearchProps {
  onLocationSelect: (location: MapboxFeature) => void; // Callback for handling selection
}

const LocationSearch: React.FC<LocationSearchProps> = ({
  onLocationSelect,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<MapboxFeature[]>([]);
  const [isSelecting, setIsSelecting] = useState<boolean>(false); // New flag to prevent API call after selection

  useEffect(() => {
    if (isSelecting) {
      setIsSelecting(false);
      return;
    }

    const fetchLocations = async () => {
      if (!searchValue.trim()) {
        setSuggestions([]);
        return;
      }

      try {
        const response = await axios.get<{ features: MapboxFeature[] }>(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            searchValue
          )}.json`,
          {
            params: {
              access_token: MAPBOX_ACCESS_TOKEN,
              autocomplete: true,
              limit: 5,
            },
          }
        );
        setSuggestions(response.data.features);
      } catch (error) {
        console.error("Error fetching location suggestions:", error);
      }
    };

    const debounceTimeout = setTimeout(fetchLocations, 300);
    return () => clearTimeout(debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleSearch = async (query: string) => {
    if (!query) return;

    try {
      const response = await axios.get<{ features: MapboxFeature[] }>(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          query
        )}.json`,
        { params: { access_token: MAPBOX_ACCESS_TOKEN } }
      );

      if (response.data.features.length > 0) {
        const place = response.data.features[0];

        setIsSelecting(true); // Prevent useEffect from triggering again
        setSearchValue(place.place_name); // Set input to selected place
        setSuggestions([]); // Clear suggestions
        onLocationSelect(place);
      }
    } catch (error) {
      console.error("Error fetching geocode data:", error);
    }
  };

  return (
    <div style={{ position: "relative", width: "100%", maxHeight: "15rem" }}>
      <TextField
        label="Search Location"
        variant="outlined"
        fullWidth
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSearch(searchValue);
          }
        }}
      />

      {suggestions.length > 0 && (
        <Paper
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 1000,
            backgroundColor: "white",
            maxHeight: "200px",
            overflowY: "auto",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)", // Optional: Adds a shadow effect
          }}
        >
          <List>
            {suggestions.map((place) => (
              <ListItem
                key={place.id}
                component="div"
                onClick={() => handleSearch(place.place_name)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemText primary={place.place_name} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
};

export default LocationSearch;
