import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SubmittedProperty } from "src/Types/submittedProperties.types";
import { Results } from "src/Pages/Services/OneTime";

interface Props {
  createdList: SubmittedProperty[];
  strData: Results;
}

const PropertyList = ({ createdList, strData }: Props) => {
  return (
    <Box sx={{ width: "100%", margin: "auto", mt: 2 }}>
      {createdList.map((property) => {
        // Find matching results for this property
        const matchingResults = strData.airbnbResults.find(
          (result) => result.propertyId === property.propertyId
        );

        return (
          <Accordion key={property.propertyId}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{property.propertyName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary">
                {property.streetAddress}, {property.city},{" "}
                {property.stateOrProvince} {property.postalCode}
              </Typography>
              {/* <Typography variant="body2">
                <strong>Owner/Manager:</strong> {property.ownerOrManagerName}
              </Typography>
              <Typography variant="body2">
                <strong>Email:</strong> {property.contactEmail}
              </Typography>
              <Typography variant="body2">
                <strong>Phone:</strong> {property.contactPhone}
              </Typography>
              <Typography variant="body2">
                <strong>Property Type:</strong> {property.propertyType}
              </Typography> */}
              <Divider sx={{ my: 2 }} />

              {/* Display Matching STR Listings */}
              {matchingResults && matchingResults.filteredResults.length > 0 ? (
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Found STR Listings:
                  </Typography>
                  {matchingResults.filteredResults.map((listing) => (
                    <Card key={listing.id} sx={{ display: "flex", my: 1 }}>
                      <CardMedia
                        component="img"
                        sx={{ width: 100, height: 100 }}
                        image={listing.pictures[0]}
                        alt={listing.name}
                      />
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography variant="body1" fontWeight="bold">
                          {listing.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {listing.title} - {listing.roomTypeCategory}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Price:</strong> {listing.pricing.price} /{" "}
                          {listing.pricing.qualifier}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Rating:</strong> {listing.rating.average}
                        </Typography>
                        <a
                          href={`https://www.airbnb.com/rooms/${listing.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Details
                        </a>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No short-term rental listings found.
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default PropertyList;
