import React, { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { Home, LocationCity, Newspaper, Security } from "@mui/icons-material";
import SignUpReleaseUpdates from "../Modals/SignUpReleaseUpdates";

export default function Navbar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Handle menu open/close
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { label: "Home", path: "/", icon: <Home /> },
    {
      label: "Free Airbnb Check",
      path: "/service/one-time",
      icon: <LocationCity />,
    },
    { label: "Blog", path: "/blog", icon: <Newspaper /> },
    { label: "Terms / Privacy", path: "/terms-privacy", icon: <Security /> },
  ];

  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Desktop Links */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            gap: 2,
          }}
        >
          {/* Logo or App Name */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            STR Monitoring
          </Typography>
          {menuItems.map((item) => (
            <Button
              key={item.label}
              component={Link}
              to={item.path}
              color="inherit"
              startIcon={item.icon}
            >
              {item.label}
            </Button>
          ))}
        </Box>

        {/* Mobile Menu */}
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {menuItems.map((item) => (
              <MenuItem
                sx={{
                  minWidth: 200,
                  alignItems: "center",
                }}
                key={item.label}
                component={Link}
                to={item.path}
                onClick={handleMenuClose}
              >
                {item.icon}
                <Typography sx={{ ml: 1 }}>{item.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        {/* Right Side Content */}
        <Box>
          <SignUpReleaseUpdates />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
