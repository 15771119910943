import React from "react";
import { Box, Card, CardMedia, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

interface Option {
  title: string;
  description: string;
  id: string;
  image?: string; // Optional image URL
}

interface OptionCardsProps {
  options: Option[];
  currentSelection: string;
  setSelection: (selection: string) => void;
}

const OptionCards: React.FC<OptionCardsProps> = ({
  options,
  currentSelection,
  setSelection,
}) => {
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    optionTitle: string
  ) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelection(optionTitle); // Select the card on Enter or Space key press
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 4, px: 2 }}
    >
      {options.map((option) => (
        <Grid key={option.title} size={{ xs: 12, sm: 12, md: 12 }}>
          <Card
            onClick={() => setSelection(option.id)}
            onKeyDown={(e) => handleKeyDown(e, option.id)} // Handle keyboard navigation
            tabIndex={0} // Make the card focusable
            sx={{
              cursor: "pointer",
              outline: "none", // Reset browser default outline
              border:
                currentSelection === option.title
                  ? "3px solid #1976d2"
                  : "1px solid #ccc",
              borderRadius: 2,
              height: "100%",
              minHeight: 500,
              transition: "box-shadow 0.3s, transform 0.2s",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                transform: "translateY(-4px)",
              },
              "&:focus-visible": {
                border: "3px solid #1976d2", // Highlight border on focus
                boxShadow: "0 0 0 4px rgba(25, 118, 210, 0.3)", // Focus ring effect
              },
            }}
          >
            <CardMedia
              component="img"
              height="250"
              image={option.image || "https://placehold.co/300x250"} // Placeholder image
              alt={option.title}
            />
            <Box p={2} textAlign="center">
              <Typography variant="h6" gutterBottom>
                {option.title}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {option.description}
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default OptionCards;
