import React from "react";
import { Box, Button, TextField, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

interface PopoverProps {
  isVisible: boolean;
  coordinates: [number, number];
  address: string;
}

interface FormData {
  "Property Name": string;
}

interface PropertyFormProps {
  popover: PopoverProps;
  submitLoading: boolean;
  setPopover: (value: PopoverProps) => void;
  handleFormSubmit: (values: FormData) => void;
}

const PropertyForm: React.FC<PropertyFormProps> = ({
  popover,
  setPopover,
  handleFormSubmit,
  submitLoading,
}) => {
  const initialValues: FormData = {
    "Property Name": "",
  };

  const validationSchema = Yup.object({
    "Property Name": Yup.string().required("Property Name is required"),
  });

  const handleClose = () => {
    setPopover({ isVisible: false, coordinates: [0, 0], address: "" });
  };

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "#fff",
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: "100%",
        position: "relative",
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "grey.500",
        }}
      >
        <CloseIcon />
      </IconButton>

      <Typography variant="body1" gutterBottom>
        {popover.address}
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({ errors, touched, values }) => (
          <Form>
            <Field
              as={TextField}
              label="Property Name"
              name="Property Name"
              fullWidth
              variant="outlined"
              size={"small"}
              error={
                touched["Property Name"] && Boolean(errors["Property Name"])
              }
              helperText={touched["Property Name"] && errors["Property Name"]}
            />
            <Box sx={{ textAlign: "right", mt: 2 }}>
              <Button
                disabled={!values["Property Name"]}
                loading={submitLoading}
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PropertyForm;
