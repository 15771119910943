export const transformMapboxAndForm = (
  values: { "Property Name": string },
  searchResults: any
) => {
  // Extracting data from searchResults
  const [lngCoordinates, latCoordinates] = searchResults.center || ["", ""];
  const addressParts = searchResults.context || [];

  const findContextValue = (type: string) => {
    const found = addressParts.find((item: any) => item.id.startsWith(type));
    return found ? found.text : "";
  };

  return {
    propertyId: searchResults.id || "", // Default empty if not found
    propertyName: values["Property Name"] || "", // From form input
    streetAddress: searchResults.address
      ? `${searchResults.address} ${searchResults.text}`
      : "",
    addressLine2: "",
    city: findContextValue("place"), // Extract city
    stateOrProvince: findContextValue("region"), // Extract state
    postalCode: findContextValue("postcode"), // Extract postal code
    country: findContextValue("country"), // Extract country
    ownerOrManagerName: "", // No data available, leaving empty
    contactEmail: "", // No data available, leaving empty
    contactPhone: "", // No data available, leaving empty
    propertyType: "", // No data available, leaving empty
    numberOfUnits: 0, // No data available, setting to 0
    notes: "", // No data available, leaving empty
    latCoordinates: latCoordinates || 0, // Extract latitude
    lngCoordinates: lngCoordinates || 0, // Extract longitude
  };
};
