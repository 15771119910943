import React from "react";
import { Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface SplitViewProps {
  leftTopComponent?: React.ReactNode; // Component to render in the top section of the left side
  leftContent: React.ReactNode; // Component to render in the main content of the left side
  rightContent: React.ReactNode; // Component to render on the right side (e.g., a Map)
  onBackClick?: () => void; // Function to handle back button click
}

const SplitView: React.FC<SplitViewProps> = ({
  leftTopComponent,
  leftContent,
  rightContent,
  onBackClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
      }}
    >
      {/* Left Section */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          borderRight: { md: "1px solid #ddd" },
          height: { xs: "100%" },
          overflowY: "auto",
          p: 2,
        }}
      >
        {/* Top Section with Back Button */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
          }}
        >
          {onBackClick && (
            <IconButton onClick={onBackClick} sx={{ mr: 1 }}>
              <ArrowBackIcon />
            </IconButton>
          )}
          {leftTopComponent}
        </Box>

        {/* Main Left Content */}
        <Box sx={{ flex: 1, overflowY: "scroll" }}>{leftContent}</Box>
      </Box>

      {/* Right Section */}
      <Box
        sx={{
          flex: 2,
          height: { xs: "100%" },
          overflow: "hidden",
        }}
      >
        {rightContent}
      </Box>
    </Box>
  );
};

export default SplitView;
