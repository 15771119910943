import React, { useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { oneTimeServiceSTRLookup } from "src/utils/apicalls";
import MapWithPins from "./Map";
import { StayData } from "src/Types/airbnb.types";
import { SubmittedProperty } from "src/Types/submittedProperties.types";
import OptionCards from "src/Components/OptionCards";
import SplitView from "src/Components/SplitView";
import { SelectionOptions } from "../serviceTypes";
import PropertyForm from "./propertyForm";
import PropertyList from "src/Components/PropertyList";
import { transformMapboxAndForm } from "src/utils/transformMapBoxAndForm";
import { MapRef } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import LocationSearch from "src/Components/MapBoxComponents/LocationSearch";

export interface CSVRow {
  [key: string]: string;
}
export interface Results {
  airbnbResults: { propertyId: string; filteredResults: StayData[] }[] | [];
}

export const options = [
  {
    id: SelectionOptions.CreateList, // Reusable enum value
    title: "Create Custom List",
    description:
      "Easily search for Airbnbs near one or multiple properties, with future support for VRBO and other short-term rental platforms.",
    image:
      "https://storage.googleapis.com/str_monitoring/blog-images/case-study-how-str-monitoring-restored-community-peace.png", // Replace with an actual image URL
  },
  // {
  //   id: SelectionOptions.AddGeoFence, // Reusable enum value
  //   title: "Create / Search via Geo Fence",
  //   description:
  //     "Create a custom search area via geofencing draw lines, or quickly locate a community, neighborhood, or city through Google Maps and define a geofence",
  //   image: "https://placehold.co/200x200", // Replace with an actual image URL
  // },
];

const Index = () => {
  const [popover, setPopover] = React.useState<{
    isVisible: boolean;
    coordinates: [number, number];
    address: string;
  }>({ isVisible: false, coordinates: [0, 0], address: "" });

  const [createdList, setCreatedList] = React.useState<SubmittedProperty[]>([]);

  const [strData, setStrData] = React.useState<Results>({
    airbnbResults: [
      // {
      //   propertyId: "1001",
      //   filteredResults: [
      //     {
      //       id: "656772912335937332",
      //       name: "Apartment in Houston  - Galleria mall",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.745315,
      //       longitude: -95.464809,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.81 (69)",
      //         label: "4.81 out of 5 average rating,  69 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-656772912335937332/original/99208f82-09a0-49fb-b830-ade907bccafb.jpeg",
      //         "https://a0.muscache.com/im/pictures/d61e1a6d-05b0-4653-a9a3-6dc347c829ec.jpg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-656772912335937332/original/3a9dd8b6-e073-4d11-8ad7-ff79bb224b22.jpeg",
      //         "https://a0.muscache.com/im/pictures/89b7b0a4-78b1-4713-9389-8b0506bf5423.jpg",
      //         "https://a0.muscache.com/im/pictures/58c118e7-d76c-455f-9efb-c0e46b37e81c.jpg",
      //         "https://a0.muscache.com/im/pictures/088d41ba-0b6c-4ee8-9ef3-b41d69bb2526.jpg",
      //       ],
      //     },
      //     {
      //       id: "978302861678136538",
      //       name: "*New* | 5 min to Galleria | Hot-tub | King Beds",
      //       title: "Home in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.73577,
      //       longitude: -95.46809,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.99 (67)",
      //         label: "4.99 out of 5 average rating,  67 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-978302861678136538/original/fe667a46-8bfc-4b15-8b52-6271ea24726b.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-978302861678136538/original/6b196573-d9d8-43fc-a8ab-153d1b93c418.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-978302861678136538/original/27273e9f-e7fa-40a8-a31c-071affd6b4b6.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-978302861678136538/original/496a881d-f3a4-4aa0-808c-111225632169.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-978302861678136538/original/c001a75f-3696-480f-915e-30b9f6a7bb24.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-978302861678136538/original/4ac4b8b7-8aab-4a6e-b048-e1aa1af4d6c4.jpeg",
      //       ],
      //     },
      //     {
      //       id: "1307786972237637084",
      //       name: "Post oak area apartments",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.745027749726404,
      //       longitude: -95.46445044453127,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.67 (3)",
      //         label: "4.67 out of 5 average rating,  3 reviews",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1307786972237637084/original/fdb3cc54-3979-4ce9-95d2-8f0286b68e35.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1307786972237637084/original/233ef871-de5c-4609-81c2-632d96c532db.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1307786972237637084/original/376c5db6-604c-43ae-8e63-504e1f7bba88.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1307786972237637084/original/aac815a0-fbdd-4340-9d80-b909c7d0550e.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1307786972237637084/original/4971e0a0-eee8-4d82-bfe5-8ab2f3ec49b2.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1307786972237637084/original/03a7eaab-32e2-415a-ad9e-9f6ac7c19257.jpeg",
      //       ],
      //     },
      //     {
      //       id: "45792486",
      //       name: "Galleria Spacious 2BR/2BT Best in UPTOWN ~ 28",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.73667,
      //       longitude: -95.46232,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.74 (23)",
      //         label: "4.74 out of 5 average rating,  23 reviews",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-45792486/original/cc37c13a-b237-4ec2-9854-cc88aeb91f0c.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-45792486/original/91e43196-58b7-41dd-9606-bab8766b9300.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-45792486/original/5e0c3ae6-400f-4a3a-875a-fa2edda0c3da.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-45792486/original/e1ed9e49-7c34-4c55-8b1f-bb80bb816f2c.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-45792486/original/751dfe0f-0888-435e-9556-de781feba878.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-45792486/original/5ea791cc-86cb-4f07-af4c-a093a685ec06.jpeg",
      //       ],
      //     },
      //     {
      //       id: "1282726348138539956",
      //       name: "Cozy Modern Galleria Bungalow",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.73232272555452,
      //       longitude: -95.46794157656434,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-03-21",
      //         checkout: "2025-03-23",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.82 (11)",
      //         label: "4.82 out of 5 average rating,  11 reviews",
      //       },
      //       badges: ["Superhost"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1282726348138539956/original/f12ab6fa-9ec6-4e72-9c55-552efcdf395a.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1282726348138539956/original/69d962f2-511e-4090-a140-9253b837f6fc.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1282726348138539956/original/f05cef00-a03f-4701-920e-8fbf464b9d81.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1282726348138539956/original/51e028f9-2864-4491-833a-3761a6288990.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1282726348138539956/original/a1a7aff9-5a43-4ea3-b33b-22a6f55e61cc.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1282726348138539956/original/0f0dd872-b7b7-4df7-bc04-b45f7d0104fc.jpeg",
      //       ],
      //     },
      //     {
      //       id: "1344106983171152735",
      //       name: "Cozy Lux Suite - Galleria",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.728873213215557,
      //       longitude: -95.46324087258509,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-21",
      //         checkout: "2025-02-23",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "New",
      //         label: "New place to stay",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1344106983171152735/original/c76b5ac0-2ed1-4ea6-a74a-7252405451e4.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1344106983171152735/original/afeedfd8-6d0f-49d0-a948-380f0861151e.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1344106983171152735/original/d523ebd3-ee99-4247-9ab5-8f8ab91610a0.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1344106983171152735/original/277f455e-e270-4821-8dca-1c020ae40952.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1344106983171152735/original/72b087b0-87c2-4036-99b5-d3b843e5fdf4.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1344106983171152735/original/7e8d59d8-3bcb-4224-9d2b-662ee23d3d0b.jpeg",
      //       ],
      //     },
      //     {
      //       id: "1266309688828640553",
      //       name: "“Home away from home”",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.73404674512301,
      //       longitude: -95.46574306030597,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "No Rating",
      //         label: "No Rating Label",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1266309688828640553/original/6cf8a054-bf4f-4400-b7bd-74f3e3831e77.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1266309688828640553/original/a73da675-382c-4af0-895a-738cc044b3aa.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1266309688828640553/original/48a7a51a-630b-49dd-81cb-21f7ee7e4afb.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1266309688828640553/original/6e1b7a93-1173-47d5-b767-92db2a386116.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1266309688828640553/original/a03898dd-f046-47e2-9252-fb9929fe5863.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1266309688828640553/original/bf34fa0d-2556-43e3-aba9-3decdd996ca8.jpeg",
      //       ],
      //     },
      //     {
      //       id: "911555697006396947",
      //       name: "Large luxury 1 Bed Getaway near Galleria!",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.74435,
      //       longitude: -95.46654,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.67 (78)",
      //         label: "4.67 out of 5 average rating,  78 reviews",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-911555697006396947/original/98b212a4-7bfc-4671-811a-ec5939881299.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-911555697006396947/original/f4b5b88f-58ac-4872-8b9f-f152c40b09cd.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-911555697006396947/original/174b6b9b-75ba-466c-8c3c-e56398913e43.jpeg",
      //         "https://a0.muscache.com/im/pictures/6d45ea37-f94a-419e-af06-4ce0cada7176.jpg",
      //         "https://a0.muscache.com/im/pictures/ab41f0c4-1926-4d53-9336-ed1e04e7fa02.jpg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-911555697006396947/original/e9175be9-9b4c-4020-abe0-3aaab3e39466.jpeg",
      //       ],
      //     },
      //     {
      //       id: "843750323008520795",
      //       name: "Cozy Apartment by Galleria",
      //       title: "Apartment in Uptown-Galleria Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.7431,
      //       longitude: -95.46994,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.77 (47)",
      //         label: "4.77 out of 5 average rating,  47 reviews",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/b65325e0-b3b7-49dc-aad4-562409c131df.jpg",
      //         "https://a0.muscache.com/im/pictures/6661ed8c-1ca3-4d65-9faf-52ee83f21f08.jpg",
      //         "https://a0.muscache.com/im/pictures/058c1d66-947f-4fcc-a437-f0bfa5c22cf1.jpg",
      //         "https://a0.muscache.com/im/pictures/39845392-1e03-41b2-90a9-78379dcf47a1.jpg",
      //         "https://a0.muscache.com/im/pictures/c7ff98a4-3871-4ab9-875f-ff04176ed7f1.jpg",
      //         "https://a0.muscache.com/im/pictures/34865441-2b9e-41e3-ad65-587994efe68f.jpg",
      //       ],
      //     },
      //     {
      //       id: "1223184545078523291",
      //       name: "2BR Galleria Condo |  Pool View | Private Entrance",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.74517,
      //       longitude: -95.46555,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-28",
      //         checkout: "2025-03-02",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "5.0 (12)",
      //         label: "5.0 out of 5 average rating,  12 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MTIyMzE4NDU0NTA3ODUyMzI5MQ%3D%3D/original/8ee58e85-a354-40a1-8c7c-908c928e2a11.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MTIyMzE4NDU0NTA3ODUyMzI5MQ%3D%3D/original/d585ab1f-d071-4398-891a-33572cc8ec02.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MTIyMzE4NDU0NTA3ODUyMzI5MQ%3D%3D/original/795a7166-e549-40a0-a19f-44b4a2c2407b.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MTIyMzE4NDU0NTA3ODUyMzI5MQ%3D%3D/original/f6fed9ce-30f7-4bc6-a8fa-30fb39be783a.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MTIyMzE4NDU0NTA3ODUyMzI5MQ%3D%3D/original/b9abad57-511a-49ff-8ab8-dd79b8aaf433.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1223184545078523291/original/2517f160-57ab-41ae-b404-d1f366de00e6.jpeg",
      //       ],
      //     },
      //     {
      //       id: "1246576312138172202",
      //       name: "Galleria luxury 2 bedroom condo",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.739134933737862,
      //       longitude: -95.45764933637561,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "5.0 (29)",
      //         label: "5.0 out of 5 average rating,  29 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1246576312138172202/original/f9f732d3-31b4-42af-a88a-a14027162a4a.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1246576312138172202/original/332862cd-4ed8-4208-b246-6f345576a01b.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1246576312138172202/original/bde8c6ed-769c-48c7-a612-3f406d79b448.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1246576312138172202/original/d0558cc1-d46b-46a4-9a91-573b0993c8fa.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1246576312138172202/original/c90d3957-6238-45f0-bbe8-6edb50122c4b.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1246576312138172202/original/3d15f1e9-6f68-48c6-b24e-85f0c0f81ba6.jpeg",
      //       ],
      //     },
      //     {
      //       id: "1332054487117833261",
      //       name: "Walkable to the Waterfall",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.73423410543144,
      //       longitude: -95.4656001642419,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "New",
      //         label: "New place to stay",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1332054487117833261/original/233c99f4-8ce5-420e-b7d0-5960340f7dae.png",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1332054487117833261/original/498782b9-0272-4b20-893d-e02311cf3a47.png",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1332054487117833261/original/320a6c19-d3c3-45db-a86a-069d1fbf7b15.png",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1332054487117833261/original/b1caf566-25da-47cc-b1e5-79753d330b7d.png",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1332054487117833261/original/9b6ebedb-b3dd-425a-b1b7-704ff9414013.png",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1332054487117833261/original/2ccea3b0-8a00-42b2-bfde-0e4dba76820a.png",
      //       ],
      //     },
      //     {
      //       id: "1332309937310891571",
      //       name: "Luxury Galleria Area Apartment",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.732514091985287,
      //       longitude: -95.45931310969361,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-28",
      //         checkout: "2025-03-02",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "5.0 (3)",
      //         label: "5.0 out of 5 average rating,  3 reviews",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1332309937310891571/original/94c0334b-ea20-4c9a-9506-b199dfc76941.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1332309937310891571/original/7a7e1474-febd-41ad-8b44-913d8f9a8c77.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1332309937310891571/original/188486c3-f1d7-44af-9da7-6fc9a67076c8.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1332309937310891571/original/28d1b6da-61fb-4cb8-bcba-2c5a3dfc9e72.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1332309937310891571/original/6bbee8ab-5888-4b36-a17d-7eeb3c04ea8e.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1332309937310891571/original/84f463c0-511e-4dcf-8c73-697cf5081e56.jpeg",
      //       ],
      //     },
      //     {
      //       id: "1248507669477027179",
      //       name: "Stunning 1-Bedroom at Galleria",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.73414579047683,
      //       longitude: -95.45890085399151,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-21",
      //         checkout: "2025-02-23",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.87 (15)",
      //         label: "4.87 out of 5 average rating,  15 reviews",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1248507669477027179/original/68e3a7cf-62d4-4388-a980-38f8dcca8a2a.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1248507669477027179/original/0820eea1-d945-4394-a8fe-e79feafecbb1.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1248507669477027179/original/fe850a5c-65d7-4748-9788-ebc73b217249.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1248507669477027179/original/80010574-5d2d-46c0-9ddb-6a0b4d509cc1.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1248507669477027179/original/a4c0cec5-940a-42a9-bf23-c01b70b3f669.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-1248507669477027179/original/2a500dc8-b2ba-40cc-9465-ed15accbe406.jpeg",
      //       ],
      //     },
      //     {
      //       id: "47997642",
      //       name: "Galleria 2BR/2BT  Location Location| Free Wifi  30",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.73653,
      //       longitude: -95.45978,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-14",
      //         checkout: "2025-02-16",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.33 (6)",
      //         label: "4.33 out of 5 average rating,  6 reviews",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-47997642/original/03457cb4-ca6f-41e3-aa9b-fc3d5016da51.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-47997642/original/5718a6db-7d31-4a39-86f6-1211148e35e1.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-47997642/original/deec0986-b083-419b-86a6-4ecba5df13ad.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-47997642/original/d77b1bad-a1ce-47bd-8c31-2e3c5bcb83e5.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-47997642/original/5d8df9a7-6abb-4903-a59f-ab129b89b43d.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-47997642/original/ef484cab-25be-45f3-927c-7581f4a001ca.jpeg",
      //       ],
      //     },
      //     {
      //       id: "892312438041880997",
      //       name: "*Premium* | Exquisite Three Story Home (HPLE)",
      //       title: "Home in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.73834,
      //       longitude: -95.46694,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-28",
      //         checkout: "2025-03-02",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.91 (22)",
      //         label: "4.91 out of 5 average rating,  22 reviews",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/60dcd6e6-0df3-4332-9bf1-51920218e48d.jpg",
      //         "https://a0.muscache.com/im/pictures/87219bb9-3f4a-4a0e-9a22-f802d5cf783e.jpg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-892312438041880997/original/7460393a-eda9-4300-b918-b4a883c7c8f7.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-892312438041880997/original/447f4833-9f0a-4cf0-b03b-b640e9d0a8f9.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-892312438041880997/original/b5e995a3-1ab4-4fab-a4d7-6ba07b0724af.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-892312438041880997/original/3188cec4-7e00-475d-b080-74e4b772190f.jpeg",
      //       ],
      //     },
      //     {
      //       id: "1258618388601035525",
      //       name: "Elegant Galleria Stay | 2BR Condo + Saltwater Pool",
      //       title: "Apartment in Houston",
      //       roomTypeCategory: "entire_home",
      //       latitude: 29.7437987538241,
      //       longitude: -95.46427524668671,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-03-28",
      //         checkout: "2025-03-30",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "5.0 (4)",
      //         label: "5.0 out of 5 average rating,  4 reviews",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1258618388601035525/original/7738ede2-7fa0-4f73-853a-1d7343746a08.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1258618388601035525/original/388542a2-d432-411a-ae07-da1f5466a4c3.png",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1258618388601035525/original/2d470680-52ca-4e34-91e0-f3312930f106.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1258618388601035525/original/920b573c-db9c-4e40-9f86-7101dfe8ca7a.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1258618388601035525/original/f4fbfc48-9470-460b-9a53-b3ff3809d3dd.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1258618388601035525/original/95367ea9-afe9-42b2-8706-2ffdbdc52ba6.jpeg",
      //       ],
      //     },
      //     {
      //       id: "827741392108916677",
      //       name: "Top Price Room-Uptown, Galleria",
      //       title: "Hotel in Houston",
      //       roomTypeCategory: "private_room",
      //       latitude: 29.73605,
      //       longitude: -95.46533,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.57 (37)",
      //         label: "4.57 out of 5 average rating,  37 reviews",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-827741392108916677/original/3696b7a8-013d-4e42-a98d-c87964603a0d.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-827741392108916677/original/01e4d5af-fe2a-42f8-add1-0e0e17e8839d.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-827741392108916677/original/9e8cc22e-ea48-4d74-9da8-077a3dead814.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-827741392108916677/original/43d121c2-ed2a-4cce-8287-95008c1e5e84.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-827741392108916677/original/624aef2c-af03-4417-8be5-9e973391e820.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-827741392108916677/original/b9b1ce23-3ab2-4020-b29e-33442d5cda45.jpeg",
      //       ],
      //     },
      //   ],
      // },
      // {
      //   propertyId: "2002",
      //   filteredResults: [
      //     {
      //       id: "660875999920348457",
      //       name: "Cozy Private Room/Bath/Entrance, Walk to Downtown!",
      //       title: "Room in Five Points",
      //       roomTypeCategory: "private_room",
      //       latitude: 39.7557,
      //       longitude: -104.97611,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-03-07",
      //         checkout: "2025-03-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.92 (213)",
      //         label: "4.92 out of 5 average rating,  213 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/08cfda68-48c1-4307-9991-339504768ff9.jpg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-660875999920348457/original/2a792a70-2d67-4529-ba5d-063524fa3ff8.jpeg",
      //         "https://a0.muscache.com/im/pictures/28cd6f05-def0-40a3-9f9e-5b03ac662007.jpg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-660875999920348457/original/8f709247-c43b-4897-9c88-c242f835d301.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-660875999920348457/original/20f7d86e-54c3-40bd-aaaf-0f6eea1d3e8b.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-660875999920348457/original/4a75e62e-f411-4e4b-9ec5-f55c68f9b3da.jpeg",
      //       ],
      //     },
      //     {
      //       id: "23308643",
      //       name: "Curtis Park Alley Flat",
      //       title: "Guesthouse in Five Points",
      //       roomTypeCategory: "entire_home",
      //       latitude: 39.75369,
      //       longitude: -104.97973,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-03-14",
      //         checkout: "2025-03-16",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.99 (483)",
      //         label: "4.99 out of 5 average rating,  483 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/a9c75a28-f8eb-470d-8a43-908a5681a4e2.jpg",
      //         "https://a0.muscache.com/im/pictures/a7ae8502-b026-46c1-bfeb-fe7ccb93525d.jpg",
      //         "https://a0.muscache.com/im/pictures/5b25462c-fa12-486d-939f-3663786f478e.jpg",
      //         "https://a0.muscache.com/im/pictures/3bcade48-5e42-4f2d-98ab-b1d13bb4b43e.jpg",
      //         "https://a0.muscache.com/im/pictures/06e62a18-ff16-4802-948f-d12e8ce150be.jpg",
      //         "https://a0.muscache.com/im/pictures/35fec531-5b5a-42cd-a94e-5a43ff261e8c.jpg",
      //       ],
      //     },
      //     {
      //       id: "650652618903478718",
      //       name: "Queen bed, attached bath, & Rooftop Patio in RiNo",
      //       title: "Room in Five Points",
      //       roomTypeCategory: "private_room",
      //       latitude: 39.75057,
      //       longitude: -104.97903,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-14",
      //         checkout: "2025-02-16",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "5.0 (211)",
      //         label: "5.0 out of 5 average rating,  211 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-650652618903478718/original/97a7ee46-79fd-4928-b02c-5d1e252bf752.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-650652618903478718/original/4774a5b3-53dd-4f2e-bab2-04e99fa244e2.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-650652618903478718/original/bd8c809a-4aff-4d91-abae-8ffa482fe752.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-650652618903478718/original/9ac07868-1bae-48b9-9321-7b053f62f4e0.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-650652618903478718/original/302a8525-ce09-4a35-a0e3-ab3ea9e944e1.jpeg",
      //         "https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6NjUwNjUyNjE4OTAzNDc4NzE4/original/fbb93ed2-b03d-49fd-998a-402ff84fd1fd.jpeg",
      //       ],
      //     },
      //     {
      //       id: "4753876",
      //       name: "RiNo Den",
      //       title: "Loft in Five Points",
      //       roomTypeCategory: "entire_home",
      //       latitude: 39.7574,
      //       longitude: -104.98677,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-07-25",
      //         checkout: "2025-07-27",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.86 (632)",
      //         label: "4.86 out of 5 average rating,  632 reviews",
      //       },
      //       badges: ["Superhost"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/cdd02bd3-0821-4166-9df0-8dd5e9bd1b17.jpg",
      //         "https://a0.muscache.com/im/pictures/270e0812-a11f-433c-aa0c-2a0224c615f1.jpg",
      //         "https://a0.muscache.com/im/pictures/b9ba55fc-04a1-41aa-b8eb-a8ef202f5123.jpg",
      //         "https://a0.muscache.com/im/pictures/0da2d45b-e47d-4a5c-b598-c00622d88891.jpg",
      //         "https://a0.muscache.com/im/pictures/de2b4592-e44c-4522-aca5-fcc97cb86b57.jpg",
      //         "https://a0.muscache.com/im/pictures/5c614c79-5cbd-4e62-ae91-6a8b6b305945.jpg",
      //       ],
      //     },
      //     {
      //       id: "887663005982573923",
      //       name: "Private Studio in Five Points!",
      //       title: "Guesthouse in Five Points",
      //       roomTypeCategory: "entire_home",
      //       latitude: 39.75044,
      //       longitude: -104.97555,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.98 (127)",
      //         label: "4.98 out of 5 average rating,  127 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-887663005982573923/original/51afbf3e-5c2f-4679-8829-66de2502ba62.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-887663005982573923/original/d5a3d59c-f40c-41f8-9e40-41cc147cab19.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-887663005982573923/original/fbb7a82a-bcae-4fd4-a93f-850a8a94ff29.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-887663005982573923/original/29781a9d-2d7b-4344-811b-c150dac80675.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-887663005982573923/original/2004eeb3-1b59-4d07-9fa3-9114899f931b.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-887663005982573923/original/4db6ee79-a2cd-408d-b349-22aedc727577.jpeg",
      //       ],
      //     },
      //     {
      //       id: "31540416",
      //       name: "Amazing Hostel Close to RiNo, Union Station, LODO",
      //       title: "Hotel room in LoDo",
      //       roomTypeCategory: "hotel_room",
      //       latitude: 39.75203,
      //       longitude: -104.9911,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.7 (757)",
      //         label: "4.7 out of 5 average rating,  757 reviews",
      //       },
      //       badges: [],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-31540416/original/ec474a94-69f7-4973-9d4a-527215786479.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-31540416/original/7fbe46b8-d33a-4541-a307-a009da846a12.jpeg",
      //         "https://a0.muscache.com/im/pictures/22aa682a-34b2-43e0-87f9-ab7df30243b9.jpg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-31540416/original/dc28e17d-8416-489b-9d24-2ef7410a8f72.jpeg",
      //         "https://a0.muscache.com/im/pictures/8d814830-9685-4661-acf6-76a869a711e2.jpg",
      //         "https://a0.muscache.com/im/pictures/a06e87bb-b508-4903-9dc4-d964f9ead419.jpg",
      //       ],
      //     },
      //     {
      //       id: "1097966491444866402",
      //       name: "The Park Room - Queen Anne Bed & Breakfast",
      //       title: "Bed and breakfast in Five Points",
      //       roomTypeCategory: "private_room",
      //       latitude: 39.747639446642296,
      //       longitude: -104.9839954134512,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-03-07",
      //         checkout: "2025-03-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.97 (37)",
      //         label: "4.97 out of 5 average rating,  37 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1097966491444866402/original/c89b034b-0e2b-42b4-a9f4-4e6c393404bd.jpeg",
      //         "https://a0.muscache.com/im/pictures/prohost-api/Hosting-1097966491444866402/original/038f1bb3-5dd0-4022-b667-6b40978780c1.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1097966491444866402/original/df1bdd05-c969-44cc-a2aa-55843fe3945b.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1097966491444866402/original/40206660-9921-4170-9a2f-9f82a1df961e.jpeg",
      //         "https://a0.muscache.com/im/pictures/prohost-api/Hosting-1097966491444866402/original/fc0a0bd6-06d2-428a-a0cf-be874efb96cd.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-1097966491444866402/original/822e9349-0da2-4d53-881c-f3efcc76e30d.jpeg",
      //       ],
      //     },
      //     {
      //       id: "23388648",
      //       name: "Better than a Hotel! Rino /Downtown",
      //       title: "Guest suite in Five Points",
      //       roomTypeCategory: "entire_home",
      //       latitude: 39.75707157060387,
      //       longitude: -104.98127177476441,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.87 (387)",
      //         label: "4.87 out of 5 average rating,  387 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/2403829a-f0f7-4525-a12d-fdbb38929b79.jpg",
      //         "https://a0.muscache.com/im/pictures/cf6714f4-366f-41e2-8845-06dc1845f827.jpg",
      //         "https://a0.muscache.com/im/pictures/c5f368c3-2a34-4231-af49-1d0d07fb0790.jpg",
      //         "https://a0.muscache.com/im/pictures/1f1a2494-772e-47ca-9069-f11a38b99ef4.jpg",
      //         "https://a0.muscache.com/im/pictures/ca16d307-723a-4306-9f5f-722e96d269f9.jpg",
      //         "https://a0.muscache.com/im/pictures/10fc02fe-5f4d-452d-9923-6e9bbd76b611.jpg",
      //       ],
      //     },
      //     {
      //       id: "14759489",
      //       name: "Private Bed and Bath in New Townhome",
      //       title: "Room in Five Points",
      //       roomTypeCategory: "private_room",
      //       latitude: 39.75369,
      //       longitude: -104.97549,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-14",
      //         checkout: "2025-02-16",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.95 (563)",
      //         label: "4.95 out of 5 average rating,  563 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/84f24e63-6d92-467e-b4f1-49d7ee52591c.jpg",
      //         "https://a0.muscache.com/im/pictures/d44a70a6-8d78-4666-966f-154e6b91acdf.jpg",
      //         "https://a0.muscache.com/im/pictures/eda8da2d-bb71-42fa-9d14-cbaf8ff075ff.jpg",
      //         "https://a0.muscache.com/im/pictures/4462c8ac-2290-4178-bb93-b7a85991549a.jpg",
      //         "https://a0.muscache.com/im/pictures/d876da24-0eee-41d1-bbfa-31369e545208.jpg",
      //         "https://a0.muscache.com/im/pictures/298cc736-c631-4b7e-8abc-2c9e76608773.jpg",
      //       ],
      //     },
      //     {
      //       id: "48423750",
      //       name: "Downtown Townhouse/Loft in RiNo/Curtis Park",
      //       title: "Loft in Five Points",
      //       roomTypeCategory: "entire_home",
      //       latitude: 39.75324,
      //       longitude: -104.9841,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.96 (74)",
      //         label: "4.96 out of 5 average rating,  74 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-48423750/original/22a87f32-a3cd-4835-9132-39222cda74ab.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-48423750/original/766b0984-6254-4f5e-a078-22f9c4bd243d.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-48423750/original/e355f145-eda0-4214-ad29-01a41b7df35f.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-48423750/original/66c7a7e3-ee93-4d44-ae9d-294e191c1b51.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-48423750/original/bdd95e9b-b19b-4707-8b50-355bc1c8d87f.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-48423750/original/e462c992-55c8-43cd-bb84-2a814bf623d7.jpeg",
      //       ],
      //     },
      //     {
      //       id: "53938883",
      //       name: "Artsy, Pristine and Perfectly Located",
      //       title: "Guest suite in Five Points",
      //       roomTypeCategory: "entire_home",
      //       latitude: 39.75678,
      //       longitude: -104.98199,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.89 (174)",
      //         label: "4.89 out of 5 average rating,  174 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-53938883/original/3da486bc-6c51-493b-bb00-5aaf66167e81.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-53938883/original/9261e643-3a97-4e90-8ab6-108e1ff2d453.jpeg",
      //         "https://a0.muscache.com/im/pictures/b4a2666c-7b1e-480e-949e-e68ae9c9f5ee.jpg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-53938883/original/f4dfbf53-125c-4bd8-9972-899af6261cb6.jpeg",
      //         "https://a0.muscache.com/im/pictures/ee235692-8add-44d3-a986-52b405de52c2.jpg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-53938883/original/990fc1d5-7ad7-4d8b-9bc0-a3c7773f3b7c.jpeg",
      //       ],
      //     },
      //     {
      //       id: "17185409",
      //       name: "Basement Bungalow",
      //       title: "Guest suite in Five Points",
      //       roomTypeCategory: "entire_home",
      //       latitude: 39.75025,
      //       longitude: -104.97276,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-03-14",
      //         checkout: "2025-03-16",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.86 (813)",
      //         label: "4.86 out of 5 average rating,  813 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/8e8ef382-c1e1-4cbc-98e9-225fdd0a457d.jpg",
      //         "https://a0.muscache.com/im/pictures/494d953f-cb85-4d61-a851-05c8fc61444b.jpg",
      //         "https://a0.muscache.com/im/pictures/31cd7da0-607c-4abc-9ebb-75364703ae96.jpg",
      //         "https://a0.muscache.com/im/pictures/6def2334-9e28-46a1-962a-26bf6a794089.jpg",
      //         "https://a0.muscache.com/im/pictures/4524cce3-0c50-4c6a-bd82-1bd3423f5539.jpg",
      //         "https://a0.muscache.com/im/pictures/07e78135-0c71-4372-bfdd-938258e91692.jpg",
      //       ],
      //     },
      //     {
      //       id: "756485570075170786",
      //       name: "ChampaHouse GuestSuite - EZAccess to Rino/Ballpark",
      //       title: "Guest suite in Five Points",
      //       roomTypeCategory: "entire_home",
      //       latitude: 39.75618,
      //       longitude: -104.98119,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.92 (130)",
      //         label: "4.92 out of 5 average rating,  130 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-756433696255823336/original/62332b8f-0544-47e1-8999-cbf46bc12b41.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-756433696255823336/original/56439eb9-2a27-474c-9db8-0ac1426cbbfc.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-756433696255823336/original/818f1c3d-9fdc-4dcb-9fd1-55d37b377144.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-756433696255823336/original/0bf4376a-676f-438c-8104-cfb15be3834e.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-756433696255823336/original/046a5951-6f89-4529-992f-cb78e1bda79e.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-756433696255823336/original/3dc03652-da30-43f6-82b4-a5b8ea35771a.jpeg",
      //       ],
      //     },
      //     {
      //       id: "52586350",
      //       name: "Modern guest house steps away from RiNo & Downtown",
      //       title: "Guesthouse in Five Points",
      //       roomTypeCategory: "entire_home",
      //       latitude: 39.75551,
      //       longitude: -104.97655,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.96 (115)",
      //         label: "4.96 out of 5 average rating,  115 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-52586350/original/d71e51be-49d2-4e9e-9849-01d3ecbd3c3e.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-52586350/original/31d13989-4b9d-4447-9d9c-b4907779259d.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-52586350/original/4131ec07-7e36-4a3f-817d-74afb480e290.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-52586350/original/c204ef40-a9c5-4126-af8f-a3b862ab13d9.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-52586350/original/bb6458e4-fe24-460a-9bb5-1a00b4775cee.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-52586350/original/f232e439-5956-4a66-beff-b8a3b315a579.jpeg",
      //       ],
      //     },
      //     {
      //       id: "959484327327719847",
      //       name: "Cozy Five Points Guest Suite",
      //       title: "Guest suite in Five Points",
      //       roomTypeCategory: "entire_home",
      //       latitude: 39.7572206,
      //       longitude: -104.9745951,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.89 (27)",
      //         label: "4.89 out of 5 average rating,  27 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-959484327327719847/original/711e0420-5b9d-48ea-a1ad-17961713003e.jpeg",
      //         "https://a0.muscache.com/im/pictures/76fcf251-f770-40d0-a379-1e5356d2d91a.jpg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-959484327327719847/original/28719c48-2683-44f8-b82b-9d766058e7c9.jpeg",
      //         "https://a0.muscache.com/im/pictures/8189ae3d-a69d-408f-8447-b233afe6f7f1.jpg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-959484327327719847/original/23ea84fd-cb6d-4980-9884-252bf2b63f39.png",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-959484327327719847/original/db45004b-075b-40d0-92b4-4754a580b2ac.png",
      //       ],
      //     },
      //     {
      //       id: "612137782283421893",
      //       name: "Private 1 Bedroom apartment close to downtown.",
      //       title: "Apartment in Five Points",
      //       roomTypeCategory: "entire_home",
      //       latitude: 39.75048,
      //       longitude: -104.97728,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-03-07",
      //         checkout: "2025-03-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.98 (249)",
      //         label: "4.98 out of 5 average rating,  249 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-612137782283421893/original/f70abcaf-1b93-4252-b21c-f6cc8bb14bd1.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-612137782283421893/original/cf0c8d59-29a7-45ca-8ef6-0d800a9b1be1.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-612137782283421893/original/e16f9dee-3509-482d-8161-d0bf2bdae5ec.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-612137782283421893/original/7472f63e-b72b-4ac1-abca-ab28fc452740.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-612137782283421893/original/4bbc3149-f9d0-446c-b0c0-f930ce2190b0.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-612137782283421893/original/b2f3af50-4c9f-44aa-bd9d-fb8b27ab47a6.jpeg",
      //       ],
      //     },
      //     {
      //       id: "993664598706076827",
      //       name: "Private 1 BR/BA in a Luxury Home in RiNo!",
      //       title: "Room in Five Points",
      //       roomTypeCategory: "private_room",
      //       latitude: 39.7571,
      //       longitude: -104.98296,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-07",
      //         checkout: "2025-02-09",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "5.0 (55)",
      //         label: "5.0 out of 5 average rating,  55 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-993664598706076827/original/31c6cb4c-db92-4d18-9662-b662e1994d28.jpeg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-993664598706076827/original/c3d0cf4c-035e-4990-88a3-607e5df17e2b.jpeg",
      //         "https://a0.muscache.com/im/pictures/1d041b86-5342-41ce-a6ef-0d484aa8f62e.jpg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-993664598706076827/original/32e4d44d-6a95-477e-a328-10004d31f50a.jpeg",
      //         "https://a0.muscache.com/im/pictures/b1677932-38eb-4d39-930c-04892ac6556e.jpg",
      //         "https://a0.muscache.com/im/pictures/miso/Hosting-993664598706076827/original/4664aa70-0b78-4c32-9ab6-4875ead8d813.jpeg",
      //       ],
      //     },
      //     {
      //       id: "52152831",
      //       name: "*NEW* Private guest suite Downtown Denver",
      //       title: "Room in Five Points",
      //       roomTypeCategory: "private_room",
      //       latitude: 39.75241,
      //       longitude: -104.97867,
      //       pdpUrlType: "ROOMS",
      //       availability: {
      //         checkin: "2025-02-14",
      //         checkout: "2025-02-16",
      //         guests: {
      //           adults: 1,
      //           children: 0,
      //           infants: 0,
      //           pets: 0,
      //         },
      //       },
      //       pricing: {
      //         price: "Price Not Available",
      //         qualifier: "Unknown Qualifier",
      //       },
      //       rating: {
      //         average: "4.93 (187)",
      //         label: "4.93 out of 5 average rating,  187 reviews",
      //       },
      //       badges: ["Guest favorite"],
      //       pictures: [
      //         "https://a0.muscache.com/im/pictures/airflow/Hosting-52152831/original/dd968d6f-f5b6-430c-9c7a-ddf3067104cf.jpg",
      //         "https://a0.muscache.com/im/pictures/airflow/Hosting-52152831/original/13f632d3-c94b-42aa-b4c9-2b22c813d4b5.jpg",
      //         "https://a0.muscache.com/im/pictures/airflow/Hosting-52152831/original/38be0a77-c69d-4c2a-8980-d72af73fa0e0.jpg",
      //         "https://a0.muscache.com/im/pictures/airflow/Hosting-52152831/original/9c417324-6b4a-4cf8-8122-860bdb4700fe.jpg",
      //         "https://a0.muscache.com/im/pictures/airflow/Hosting-52152831/original/585814f6-a57a-44a6-83af-c829c33d2397.jpg",
      //         "https://a0.muscache.com/im/pictures/airflow/Hosting-52152831/original/b8c6957d-aa70-4a57-849c-d352467ea07b.jpg",
      //       ],
      //     },
      //   ],
      // },
    ],
  });
  const [currentSelection, setSelection] = React.useState("");
  const [searchResults, setSearchResults] = React.useState({});
  const [submitLoading, setSubmitLoading] = React.useState(false);

  // Handle CSV upload

  // const handleCSVUpload = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   if (!event.target.files || event.target.files.length === 0) return;

  //   const file = event.target.files[0];

  //   try {
  //     const text = await file.text();
  //     console.log("CSV file contents:", text);

  //     // Split the file content into rows (filter out empty lines)
  //     const rows = text.split("\n").filter((row) => row.trim() !== "");

  //     // Extract headers from the first row
  //     const headers = rows[0].split(",").map((header) => header.trim());

  //     // Define which fields are required
  //     const requiredFields = [
  //       "City",
  //       "State/Province",
  //       "Postal Code",
  //       "Street Address",
  //     ];

  //     let hasError = false;

  //     // Build an array of objects (one per row)
  //     const csvDataArray: CSVRow[] = rows.slice(1).map((row, rowIndex) => {
  //       const values = row.split(",").map((value) => value.trim());
  //       const rowData: CSVRow = {};

  //       headers.forEach((header, colIndex) => {
  //         const value = values[colIndex] || "";
  //         rowData[header] = value;

  //         // Check for missing values in required fields
  //         if (requiredFields.includes(header) && !value) {
  //           hasError = true;
  //           console.error(
  //             `Validation Error: "${header}" is missing in row ${rowIndex + 2}`
  //           );
  //         }
  //       });

  //       return rowData;
  //     });

  //     // If there are errors, dispatch the Snackbar error message
  //     if (hasError) {
  //       dispatch({
  //         type: "Snackbar",
  //         payload: {
  //           show: true,
  //           duration: 10000,
  //           message:
  //             "Error: Some required fields (City, State/Province, Postal Code, or Street Address) are missing in the uploaded CSV. Please fix and try again.",
  //           severity: "error",
  //         },
  //       });
  //       return;
  //     }

  //     // If no errors, set state and dispatch success
  //     console.log("Parsed CSV Data as Array of Objects:", csvDataArray);

  //     handleSendToBackend(csvDataArray);

  //     dispatch({
  //       type: "Snackbar",
  //       payload: {
  //         show: true,
  //         message:
  //           "You’ve successfully subscribed to updates and notifications for STR Monitoring. Thank you!",
  //         severity: "success",
  //       },
  //     });
  //   } catch (err) {
  //     console.error("Error processing CSV file:", err);
  //     dispatch({
  //       type: "Snackbar",
  //       payload: {
  //         show: true,
  //         message: "An error occurred while processing the CSV file.",
  //         severity: "error",
  //       },
  //     });
  //   }
  // };

  const handleSendToBackend = async (
    csvDataArray: CSVRow[] | SubmittedProperty[]
  ) => {
    setSubmitLoading(true);
    oneTimeServiceSTRLookup(
      csvDataArray,
      (res: any) => {
        setStrData(res.data);
        setCreatedList(res.data.submittedProperties);
        setSubmitLoading(false);
      },
      (err) => {
        console.error("Error calling geocode endpoint:", err);
        setSubmitLoading(false);
      }
    );
  };

  const handleFormSubmit = (values: { "Property Name": string }) => {
    console.log("form values", values);
    console.log("searchResults", searchResults);

    const transformedData = transformMapboxAndForm(values, searchResults);

    // Update the createdList with the new merged object
    setCreatedList((prevList: any) => [...prevList, transformedData]);

    // Close the popover
    setPopover({ isVisible: false, coordinates: [0, 0], address: "" });
  };

  const leftTopComponent = (
    <Typography variant="h6">Return To Selections</Typography>
  );

  const mapRef = useRef<MapRef>(null);

  const handleLocationSelect = (location: {
    center: [number, number];
    place_name: string;
  }) => {
    console.log("Selected Location:", location);

    const map = mapRef.current?.getMap(); // FIX: Extract the actual Mapbox instance

    if (map) {
      new mapboxgl.Marker().setLngLat(location.center).addTo(map);
      map.flyTo({ center: location.center, zoom: 14 });

      setSearchResults(location); // Store search results in state

      // Update popover with location info
      setPopover({
        isVisible: true,
        coordinates: location.center,
        address: location.place_name,
      });
    }
  };

  const leftContent = (
    <div
      style={{
        maxHeight: "80vh",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {/* Search Input */}
      <LocationSearch onLocationSelect={handleLocationSelect} />

      {/* Property Form */}
      {popover.isVisible && (
        <PropertyForm
          submitLoading={submitLoading}
          popover={popover}
          setPopover={setPopover}
          handleFormSubmit={handleFormSubmit}
        />
      )}

      {/* Property List */}
      <PropertyList strData={strData} createdList={createdList} />

      {createdList.length >= 1 && (
        <Button
          loading={submitLoading}
          variant="contained"
          color="primary"
          onClick={() => handleSendToBackend(createdList)}
        >
          Submit
        </Button>
      )}
    </div>
  );

  const rightContent = (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <MapWithPins
        mapRef={mapRef}
        submittedProperties={createdList}
        setPopover={setPopover}
        popover={popover}
        createdList={createdList}
        setSearchResults={setSearchResults}
        foundSTRProperties={strData}
      />
    </Box>
  );

  const handleBackClick = () => {
    setSelection("");
  };

  return (
    <>
      {currentSelection === "" && (
        <OptionCards
          options={options}
          currentSelection={currentSelection}
          setSelection={setSelection}
        />
      )}
      {currentSelection !== "" && (
        <SplitView
          leftTopComponent={leftTopComponent}
          leftContent={leftContent}
          rightContent={rightContent}
          onBackClick={handleBackClick}
        />
      )}
    </>
  );
};

export default Index;
